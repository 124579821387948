import { __ } from '@wordpress/i18n';

/**
 * React hook that is used to mark the block wrapper element.
 * It provides all the necessary props like the class name.
 *
 * @see https://developer.wordpress.org/block-editor/reference-guides/packages/packages-block-editor/#useblockprops
 */
import { useBlockProps } from '@wordpress/block-editor';

/**
 * Lets webpack process CSS, SASS or SCSS files referenced in JavaScript files.
 * Those files can contain any CSS code that gets applied to the editor.
 *
 * @see https://www.npmjs.com/package/@wordpress/scripts#using-css
 */
import './editor.scss';


import { useState ,useEffect } from 'react';
import { BlockFormatControls } from '@wordpress/block-editor'
import { Button,SelectControl ,Modal} from '@wordpress/components'
import { registerFormatType,insert} from '@wordpress/rich-text'
import classnames from 'classnames'
import apiFetch from '@wordpress/api-fetch';



export const FsDecopressToolbarDropdown = props => {

//    console.log(props);
	const {
		formatTypeName,		// フォーマットタイプ名 (例 : my-plugin/my-custom-format)
		formatTitle,		// フォーマットのタイトル
		formatClass,		// ラップするHTMLタグに挿入する class
		tagName,		// ラップするHTMLタグ(例 : span)
		toolbarButtonClass,	// ツールバーボタンに挿入するclass
		toolbarButtonIcon,	// ツールバーボタンのアイコン
		toolbarButtonLabel,	// ツールバーボタンのラベル
		tooltipLabel,		// ツールチップのテキスト
	} = props


	const DropdownPopover = props => {

		// const storedFunctionValue = window.localStorage.getItem('hsn-decopress-function');

        const myBlockData = fs_decopress_gutenberg_comboboxData;

// 		const [radioValue, setRadioValue] = useState(storedFunctionValue || '');
        const [isOpen, setOpen] = useState(false);
        const [inputValue, setInputValue] = useState('');
//        const [posts, setPosts] = useState([]);
//        const [isLoading, setIsLoading] = useState(false);

        const [parentComboValue, setParentComboValue] = useState('');
        const [childComboOptions, setChildComboOptions] = useState([]);
        const [childComboValue, setChildComboValue] = useState('');

		// registerFormatType からのプロパティ
		const {
			activeAttributes,	// 選択テキストに既に適用されているフォーマットがある場合にその class が入る
			isActive,			// 選択テキストに現在のフォーマットが含まれる場合は true になる
			onChange,			// ドロップダウン内のボタンがクリックされたときの処理
			value,				// 対象のテキスト
		} = props

		const className = classnames(
			[
				'components-icon-button',
				'components-toolbar__control',
				`deb-toolbar-button__${ toolbarButtonClass }`,
			],
			{
				'is-pressed' : isActive,
			}
		)

        const openModal = () => {
//            console.log('*************');
//            console.log(value.text.slice(value.start, value.end));
//            console.log(value.text);
            setOpen(true);
        };

    /*
		const handleRadioChange = (event) => {
			const newValue = event.target.value;
			setRadioValue(newValue);  // ラジオボタンの選択値を更新

			// 選択をローカルストレージに保存
			window.localStorage.setItem('hsn-decopress-function', newValue);
		};


		const onFunctionSelect = () => {
            console.log('テスト');

            setInputValue('テスト');
			onChange(insert(value, inputValue, value.start, value.end));
			// setInputValue('');

		};
*/
        useEffect(() => {
            if (myBlockData.comboOptions && myBlockData.comboOptions.length > 0) {
                // 初期の親コンボボックスの値を設定
                const initialValue = myBlockData.comboOptions[0].text;
                setParentComboValue(initialValue);
        
//                console.log('useEffect');

                // 子コンボボックスの初期オプションを設定
                if (myBlockData.comboOptions[0].menu) {
                    setChildComboOptions([{ text: '未選択', value: '' }, ...myBlockData.comboOptions[0].menu]);
                    setChildComboValue(''); // 子コンボボックスの初期選択値を「未選択」に設定
        
                }
            }
        }, []);

        const handleParentComboChange = (selectedValue) => {
            setParentComboValue(selectedValue);
//            console.log(selectedValue);
        
            // 親の選択に基づいて子コンボボックスのオプションを更新
            const selectedOption = myBlockData.comboOptions.find(option => option.text === selectedValue);
            if (selectedOption) {
//                setChildComboOptions(selectedOption.menu);
                setChildComboOptions([{ text: '未選択', value: '' }, ...selectedOption.menu]);
                setChildComboValue(''); 
            } else {
                setChildComboOptions([{ text: '未選択', value: '' }]);
                setChildComboValue(''); 
            }
        };

        const handleChildComboChange = (selectedShotrCode) => {
            setChildComboValue(selectedShotrCode);
          
            // 選択されているテキストを取得
            const selectedText = value.text.slice(value.start, value.end);

//            const regex = /(\[decopress\s+([^\]]+)\])([\s\S]*)(\[\/decopress\])/;

// selectedShotrCode = '[decopress id=15 att="abc"]'
// selectedShotrCode = '[decopress id=15 att="abc"]doudou[/decopress]'
// selectedShotrCode = '[decopress id=15 att="abc"][/decopress]'


            let shortcode = '';
            let newValue = '';


            if (selectedShotrCode.includes('[/decop_')) {
                    const regex = /(\[decop_[^\]]*\])([\s\S]*?)(\[\/decop_[^\]]*\])/;                

                // 正規表現を使用してショートコードを分割
                const matches = regex.exec(selectedShotrCode);
        
                if (matches) {
                    const startTag = matches[1]; // 開始タグ
                    const message = matches[2] ? matches[2] : ''; // メッセージ部分（存在する場合）
                    const endTag = matches[3] ? matches[3] : ''; // 終了タグ（存在する場合）

                    // 選択されている文字列があればその値を優先する
                    if (selectedText){
                        shortcode = startTag + selectedText + endTag ; 
                    }else{
                        shortcode = startTag + message + endTag ; 
                    }

                    // 現在のテキスト値、挿入するテキスト、挿入位置を指定
                        
                
                } else {
                    // ショートコードが一致しない場合の処理
                    console.log("No match found");
                }

              } else {
                shortcode = selectedShotrCode; 


              }

            // 現在のテキスト値、挿入するテキスト、挿入位置を指定
            newValue = insert(value, shortcode, value.start, value.end);
            // 更新された値でonChangeを呼び出す
            onChange(newValue);
            setOpen(false);
        };

//        console.log(myBlockData.comboOptions );

		// カスタムツールバー表示
		return (
			<BlockFormatControls>
			<Button
					className={ className }
					icon={ toolbarButtonIcon }
					label={ tooltipLabel }
					aria-haspopup="true"
					aria-label={ toolbarButtonLabel }
                    onClick={openModal}></Button>

                    {isOpen && (
                    <Modal title="デコプレス" onRequestClose={() => setOpen(false)}>
                {/* 親コンボボックス */}
                <SelectControl
                    label="種類選択"
                    value={parentComboValue}
                    options={myBlockData.comboOptions.map(option => ({ label: option.text, value: option.text }))}
                    onChange={handleParentComboChange}
                />
                {/* 子コンボボックス */}
                <SelectControl
                    label="パーツ選択"
                    value={'未選択'}
                    options={childComboOptions.map(option => ({ label: option.text, value: option.value }))}
                    onChange={handleChildComboChange}
                />
					</Modal>
                    )}
			</BlockFormatControls>
		)
	}
    // フォーマットを登録
    registerFormatType(
		formatTypeName, {
			title: formatTitle,
			tagName: tagName,
			className: formatClass,
            edit: function(props) {
                return <DropdownPopover {...props} />;
            },
			attributes: {
				class: 'class',
				style: 'style',
			},
		}
	)

}

FsDecopressToolbarDropdown( {
	formatTypeName : 'fs-decopress-block/custom-toolbar',
    formatTitle : 'decopress',		// フォーマットのタイトル
    formatClass : 'decopressclss',		// ラップするHTMLタグに挿入する class
    tagName : 'decopresstag',		// ラップするHTMLタグ(例 : span)
	slotName : 'decopress',
	toolbarClass: 'tdecopress',
	toolbarIcon : 'art',
    toolbarButtonIcon : 'art',
    toolbarLabel : __( 'デコプレス' ),
	dropdownPosition : 'bottom center',
    tooltipLabel : 'デコプレス',		// ツールチップのテキスト
});